import { FC, useMemo } from 'react';

import { ParentProductRo } from '@api/ocb-digital/offer/types';
import { SxTheme } from '@lib/theme/types';
import { Box, Card } from '@mui/material';
import { OfferCardTitle } from '@templates/common/offer-card/OfferCardTitle';
import { Price } from '@ui/price/Price';

import { CtaProps, ParentProductWithTextLines } from '../types';
import { CtaButton } from './CtaButton';
import { ImageContainer } from './ImageContainer';
import { ProductDescription } from './ProductDescription';
import { SubscriptionTerm } from './SubscriptionTerm';
import { TextLines } from './TextLines';

interface Props {
  item: ParentProductRo;
  ctaProps?: CtaProps;
  showHeader?: boolean;
  showCta?: boolean;
  fullWidth?: boolean;
  cardLineSx?: SxTheme;
  cardTitleContainerSx?: SxTheme;
  cardTitleSx?: SxTheme;
}

export const ParentProductCard: FC<Props> = ({
  item,
  ctaProps,
  showCta = true,
  cardTitleContainerSx,
  cardTitleSx,
}) => {
  const mappedItem = useMemo(
    (): ParentProductWithTextLines | null =>
      item ? mapParentProductsItem(item) : null,
    [item],
  );

  if (!mappedItem) {
    return null;
  }

  const {
    image,
    title,
    parentProduct: baseOffer,
    showProductPricing,
    tags,
    tagsTitle,
    showImage,
  } = mappedItem;

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '16px',
        border: '1px solid #E6E6E6',
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {showImage && <ImageContainer image={image} />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          height: '100%',
        }}
      >
        {title && (
          <OfferCardTitle
            containerSx={{ ...cardTitleContainerSx, px: 0, pb: '6px' }}
            sx={{
              ...cardTitleSx,
              color: undefined,
              fontWeight: 700,
              fontSize: 18,
              lineHeight: '21px',
            }}
          >
            {title}
          </OfferCardTitle>
        )}
        <ProductDescription description={baseOffer?.description} />
        {showProductPricing && (
          <Price
            sx={{ justifyContent: 'flex-start', px: 0, mt: 2 }}
            pricing={baseOffer?.productPricing}
          />
        )}
        <SubscriptionTerm subscriptionTerm={baseOffer?.subscriptionTerm} />
        <TextLines tags={tags} tagsTitle={tagsTitle} />
        <Box flexGrow={1} />
        {showCta && (
          <Box display="flex" alignSelf="center" mt={2}>
            <CtaButton
              ctaProps={ctaProps}
              offerId={baseOffer?.offerId}
              productId={baseOffer?.productId}
            />
          </Box>
        )}
      </Box>
    </Card>
  );
};

function mapParentProductsItem(
  item: ParentProductRo,
): ParentProductWithTextLines {
  return {
    showProduct: item.showProduct,
    showBestValueTag: item.showBestValueTag,
    showProductPricing: item.showProductPricing ?? true,
    showLimitedProductTag: item.showLimitedProductTag,
    showImage: item.showImage,
    defaultSimProduct: item.defaultSimProduct,
    parentProduct: item.blob,
    title: item.blob.commercialName,
    tags: item.tags,
    tagsTitle: item.tagsTitle,
    image: item.image,
  };
}
