import { FC, useMemo } from 'react';
import { DefaultProductListVariation } from '../types';
import { ProductListContent } from './ProductListContent';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';
import { useParentProducts } from '@templates/purchase/common/useParentProducts';

interface Props {
  slice: DefaultProductListVariation;
}

export const DefaultProductList: FC<Props> = ({ slice }) => {
  const { filter, paddingTop, paddingBottom } = slice.primary;
  const offerCategoryId: string = useMemo(
    () =>
      filter
        ?.map((item) => item.offerCategoryId)
        .filter(Boolean)
        .join(','),
    [filter],
  );
  const { products, isLoading, isError } = useParentProducts({
    offerCategoryId,
  });

  return (
    <SliceLayout paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <ProductListContent
        products={products}
        productsCount={products?.length}
        offerCategoryId={offerCategoryId}
        isLoading={isLoading}
        isError={!!isError}
      />
    </SliceLayout>
  );
};
